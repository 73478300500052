import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useRef, useContext, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { UserContext, ProjectContext, BaseUrlContext, ConfigContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useUserPermissions from '@core/hooks/useUserPermissions';

import Box from '@ui/Box';
import Button from '@ui/Button';
import DateLine from '@ui/DateLine';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import MarkdownEditor from '@ui/MarkdownEditor';
import { remove } from '@ui/MarkdownEditor/editor/transforms';
import Modal, { ModalBody, ModalFooter } from '@ui/Modal';
import { notify, ErrorNotification } from '@ui/Notification';
import RDMD from '@ui/RDMD';
import Title from '@ui/Title';

import { handleAddComment, handleCommentVote, handleDelete, handleUpdate } from '../helpers';
import { rdmdOpts } from '../options';
import classes from '../style.module.scss';

const Vote = props => {
  const {
    baseUrl,
    comment: { id, votes },
    isClient,
    setErrorMessage,
    permissions,
    userId,
  } = props;
  const [voteData, setVoteData] = useState({
    upVote: votes.filter(v => v.value === 1),
    downVote: votes.filter(v => v.value === -1),
  });

  const commentVoteUrl = `${isClient ? window.location.pathname : baseUrl}/comments/${id}/vote`;
  /**
   * `v.user` and `userId` are mongoose ObjectIds on the server,
   * so a ObjectId comparison is required here!
   * @link https://stackoverflow.com/a/11638106
   */
  const votedUp =
    voteData.upVote.filter(v => {
      if (typeof v.user === 'object') return v.user.equals(userId);
      return v.user === userId;
    }).length > 0;
  const votedDown =
    voteData.downVote.filter(v => {
      if (typeof v.user === 'object') return v.user.equals(userId);
      return v.user === userId;
    }).length > 0;

  return (
    <Flex gap="xs" justify="start" layout="col">
      <Button
        className={`${classes['DiscussPost-button']} ${classes['DiscussPost-button_comment']}`}
        kind={votedUp ? 'primary' : 'secondary'}
        onClick={() =>
          handleCommentVote({
            url: commentVoteUrl,
            permissions,
            voteType: 'upVote',
            voteData,
            setErrorMessage,
            setVoteData,
            userId,
          })
        }
        outline
        size="md"
      >
        <i className={`${classes['DiscussPost-button_icon']} icon-chevron-up`} />
        {voteData.upVote?.length || 0}
      </Button>
      <Button
        className={`${classes['DiscussPost-button']} ${classes['DiscussPost-button_comment']}`}
        kind={votedDown ? 'primary' : 'secondary'}
        onClick={() =>
          handleCommentVote({
            url: commentVoteUrl,
            permissions,
            voteType: 'downVote',
            voteData,
            setVoteData,
            userId,
          })
        }
        outline
        size="md"
      >
        <i className={`${classes['DiscussPost-button_icon']} icon-chevron-down`} />
        {voteData.downVote?.length || 0}
      </Button>
    </Flex>
  );
};

const Meta = props => {
  const { createdAt, edited, user } = props;
  const { isLoggedIn, isAdminUser } = useUserPermissions();

  return (
    <div className={classes['DiscussPost-details']}>
      <DateLine
        className={classes['DiscussPost-date']}
        icon="icon-clock"
        suffix={`by ${user.name || 'Anonymous'}`}
        tag="span"
        time={createdAt}
      />
      {!!user.isAdmin && <span className={classes['DiscussPost-admin']}>Admin</span>}
      {!!isLoggedIn && !!isAdminUser && !!user.email && (
        <span className={classes['DiscussPost-email']}>
          <span className={`${classes.icon} icon-lock1`} />
          <span>{user.email}</span>
        </span>
      )}
      {!!edited && <span className={classes['DiscussPost-edited']}>(edited)</span>}
    </div>
  );
};

const Content = props => {
  const { comment, commentUrl, isLoggedIn, isAdminUser, postComments, setIsEditing, setPostComments, user } = props;
  const deleteModalRef = useRef();
  const permanentlyDeleteModalRef = useRef();
  const isAuthor = isLoggedIn && comment.hub2user?.user?.email === user.email;

  return (
    <>
      <RDMD className={classes['DiscussPost-fullbody']} opts={rdmdOpts}>
        {comment.body}
      </RDMD>
      {!!isLoggedIn && (
        <div className={classes.DiscussPostActions}>
          {!!(comment.solved || comment.reopened) && (
            <SolvedBlock body={comment.body} solved={comment.solved} userName={comment.effective_user?.name} />
          )}
          {!!isAuthor && !comment.deleted && (
            <ModifyCommentButton icon="icon-edit-2" onClickAction={() => setIsEditing(true)}>
              Edit
            </ModifyCommentButton>
          )}
          {!!(isAuthor || isAdminUser) && !comment.deleted && (
            <ModifyCommentButton icon="icon-trash1" onClickAction={() => deleteModalRef.current.toggle(true)}>
              Delete
            </ModifyCommentButton>
          )}
          {!!isAdminUser && !!comment.deleted && (
            <ModifyCommentButton
              icon="icon-trash1"
              onClickAction={() => permanentlyDeleteModalRef.current.toggle(true)}
            >
              Permanently Delete
            </ModifyCommentButton>
          )}
          <ConfirmDeleteModal
            comment={comment}
            commentUrl={commentUrl}
            modalRef={deleteModalRef}
            postComments={postComments}
            setPostComments={setPostComments}
            target={`#delete-discussion-comment-modal-target-${comment.id}`}
          />
          <ConfirmDeleteModal
            comment={comment}
            commentUrl={`${commentUrl}/permanently`}
            modalRef={permanentlyDeleteModalRef}
            permanent
            postComments={postComments}
            setPostComments={setPostComments}
            target={`#permanently-delete-discussion-comment-modal-target-${comment.id}`}
          />
        </div>
      )}
    </>
  );
};

const ContentEditor = props => {
  const { comment, commentUrl, postComments, setPostComments, setIsEditing } = props;
  const baseUrl = useContext(BaseUrlContext);
  const { domainFull } = useContext(ConfigContext);

  // Set up MarkdownEditor state
  const [editor, setEditor] = useState();
  const onInit = useCallback(e => setEditor(e), []);
  const onChange = useCallback((_, e) => setEditor(e), []);

  const updateComment = () => {
    // In the new editor, editor.toString() can't be blank.
    // In the old editor, newComment can't be blank.
    const updatedComment = editor?.toString() || '';
    if (!updatedComment) {
      return notify(<ErrorNotification>Your comment cannot be blank.</ErrorNotification>);
    }

    return handleUpdate({
      url: commentUrl,
      editedBody: updatedComment,
      updateSuccess: () => {
        const updatedPostComments = Object.values(postComments).map(c => {
          if (c.id === comment.id) {
            c.body = updatedComment;
            c.edited = true;
          }
          return c;
        });
        setPostComments(updatedPostComments);
        setIsEditing(false);
        setEditor(null);
      },
    });
  };

  return (
    <>
      <Box className={classes.DiscussEditorWrapper} kind="rule">
        <MarkdownEditor
          basic
          className={classes['DiscussPost-fullbody']}
          doc={comment.body}
          domainFull={domainFull}
          imageUpload={false}
          onChange={onChange}
          onInit={onInit}
          projectBaseUrl={baseUrl}
        />
      </Box>
      <div className={classes['DiscussPost-edit']}>
        <Button
          className={`${classes['Discuss-question_button']} ${classes['DiscussPost-edit_button']}`}
          onClick={updateComment}
          size="sm"
        >
          Save
        </Button>
        <Button kind="secondary" onClick={() => setIsEditing(false)} outline size="sm">
          Cancel
        </Button>
      </div>
    </>
  );
};

const SolvedBlock = props => {
  const { solved, body, userName } = props;
  const markedText = solved ? 'Marked as answered' : 'Marked as reopened';
  return (
    <span className={`${classes['DiscussPost-solved']} ${solved ? classes['DiscussPost-solved_answered'] : ''}`}>
      <span className={`${classes['DiscussPost-solved_icon']} ${solved ? 'icon-check1' : 'icon-x'}`} />
      <span>
        {markedText}
        {!body && ` by ${userName}`}
      </span>
    </span>
  );
};

const ModifyCommentButton = props => {
  const { onClickAction, icon, children } = props;
  return (
    <Button
      className={`${classes['DiscussPostActions-action']} ${classes['DiscussPostActions-action_comment']}`}
      kind="secondary"
      onClick={() => onClickAction()}
      outline
      size="sm"
    >
      <i className={`${classes.DiscussPost_icon} ${icon}`} />
      {children}
    </Button>
  );
};

const ConfirmDeleteModal = props => {
  const { modalRef, comment, commentUrl, permanent, postComments, setPostComments, target } = props;

  return (
    <Modal ref={modalRef} size="sm" target={target} verticalCenter>
      <ModalBody>
        <Title className={classes['DiscussPost-delete_text']} level={6}>
          Are you sure you want to{permanent ? ' permanently ' : ' '}delete this comment?
        </Title>
      </ModalBody>
      <ModalFooter justify="center">
        <Button
          kind="secondary"
          onClick={() => {
            modalRef.current.toggle(false);
          }}
        >
          Cancel
        </Button>
        <Button
          kind="secondary"
          onClick={() => {
            const updatedPostComments = permanent
              ? Object.values(postComments).filter(c => c.id !== comment.id)
              : Object.values(postComments).map(c => {
                  if (c.id === comment.id) {
                    c.body = '*This comment has been deleted*';
                    c.deleted = true;
                  }
                  return c;
                });
            handleDelete({ url: commentUrl, setPostComments, updatedPostComments });
            modalRef.current.toggle(false);
          }}
        >
          {permanent ? 'Permanently ' : ''}Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const Comment = props => {
  const {
    baseUrl,
    comment,
    isLoggedIn,
    isAdminUser,
    isClient,
    permissions,
    postComments,
    setErrorMessage,
    setPostComments,
    user,
    userId,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const commentUrl = `${isClient ? window.location.pathname : baseUrl}/comments/discuss/${comment.id}`;

  return (
    <div className={classes.DiscussPost} id={`comment-id-${comment.id}`}>
      {!!comment.body && !(comment.solved || comment.deleted || comment.reopened) && (
        <Vote {...{ baseUrl, comment, isClient, setErrorMessage, permissions, userId }} />
      )}
      <div className={classes['DiscussPost-content']}>
        {comment.body ? (
          <>
            <Meta createdAt={comment.createdAt} edited={comment.edited} user={comment.effective_user} />
            {isEditing ? (
              <ContentEditor {...{ comment, commentUrl, postComments, setPostComments, setIsEditing }} />
            ) : (
              <Content
                {...{ comment, commentUrl, isAdminUser, isLoggedIn, postComments, setIsEditing, setPostComments, user }}
              />
            )}
            <div className="ModalWrapper" id={`delete-discussion-comment-modal-target-${comment.id}`}></div>
            <div className="ModalWrapper" id={`permanently-delete-discussion-comment-modal-target-${comment.id}`}></div>
          </>
        ) : (
          <SolvedBlock solved={comment.solved} userName={comment.effective_user?.name} />
        )}
      </div>
    </div>
  );
};

const AddComment = props => {
  const {
    baseUrl,
    id,
    isClient,
    isLoggedIn,
    isAdminUser,
    postComments,
    setErrorMessage,
    setPostComments,
    solved,
    user,
  } = props;
  const { project } = useContext(ProjectContext);
  const {
    reCaptchaSiteKey,
    flags: { disableAnonForum },
  } = project;
  const { domainFull } = useContext(ConfigContext);
  const recaptchaRef = useRef();
  const [newComment, setNewComment] = useState('');
  const [loggedOutUser, setLoggedOutUser] = useState({
    name: '',
    email: '',
  });
  const [isSolved, setIsSolved] = useState(solved);

  const [editor, setEditor] = useState();
  const onInit = useCallback(e => setEditor(e), []);
  const onChange = useCallback((_, e) => setEditor(e), []);

  const newCommentUrl = `${isClient ? window.location.pathname : baseUrl}/comments/discuss`;

  const addComment = async ({ added, commentReopened, commentSolved }) => {
    const commentUser = user || loggedOutUser;

    const body = editor?.toString();

    if (added) {
      if (!body) {
        return notify(<ErrorNotification>Your comment cannot be blank.</ErrorNotification>);
      }

      if (reCaptchaSiteKey && recaptchaRef) {
        recaptchaRef.current?.reset();
      }

      if (!isLoggedIn && (!loggedOutUser.name || !loggedOutUser.email))
        return notify(<ErrorNotification>Please fill out your name and email.</ErrorNotification>);
    }

    let recaptchaResponse;
    if (reCaptchaSiteKey) {
      recaptchaResponse = await recaptchaRef.current.executeAsync();
    }

    return handleAddComment({
      recaptchaResponse,
      url: newCommentUrl,
      anonymous: !isLoggedIn,
      body,
      parent: id,
      commentReopened,
      commentSolved,
      user: commentUser,
      setErrorMessage,
      updateSuccess: c => {
        // Update comment list
        const updatedComments = cloneDeep(postComments);
        updatedComments[c.id] = c;
        setPostComments(updatedComments);

        // Reset editor and logged out user placeholder text
        setLoggedOutUser({ name: '', email: '' });
        setNewComment('');
        if (editor) remove(editor);

        // Toggle the solved status of the post itself
        setIsSolved(commentReopened || commentSolved || isSolved);
      },
    });
  };

  return (
    <div className={classes.DiscussPost}>
      {!!disableAnonForum && !isLoggedIn ? (
        <Button href={`${domainFull}/to/${project.subdomain}?redirect=/discuss-new`} outline size="sm">
          <Icon name="message-circle" />
          <b>Log in</b> to add a comment.
        </Button>
      ) : (
        <div className={classes['DiscussPost-content']}>
          <Box className={classes.DiscussEditorWrapper} kind="rule">
            <MarkdownEditor
              basic
              className={classes['DiscussPost-newComment_content']}
              doc={newComment}
              domainFull={domainFull}
              imageUpload={false}
              onChange={onChange}
              onInit={onInit}
            />
          </Box>
          {!isLoggedIn && (
            <div className={classes['DiscussPost-newComment_inputs']}>
              <Input
                className={classes['DiscussPost-newComment_input']}
                onChange={e => setLoggedOutUser(prevState => ({ ...prevState, name: e.target.value }))}
                placeholder="Full name"
                required
                type="text"
                value={loggedOutUser.name}
              />
              <Input
                className={classes['DiscussPost-newComment_input']}
                onChange={e => setLoggedOutUser(prevState => ({ ...prevState, email: e.target.value }))}
                placeholder="name@email.com"
                required
                type="email"
                value={loggedOutUser.email}
              />
            </div>
          )}
          <div className={classes['DiscussPost-edit']}>
            <Button
              className={`${classes['Discuss-question_button']} ${classes['DiscussPost-edit_button']}`}
              onClick={() => addComment({ added: true })}
              size="sm"
            >
              Add Comment
            </Button>
            {!!isAdminUser &&
              (isSolved ? (
                <Button kind="secondary" onClick={() => addComment({ commentReopened: true })} outline size="sm">
                  {newComment.length ? 'Comment and reopen' : 'Mark as unanswered'}
                </Button>
              ) : (
                <Button kind="secondary" onClick={() => addComment({ commentSolved: true })} outline size="sm">
                  {newComment.length ? 'Comment and mark answered' : 'Mark as answered'}
                </Button>
              ))}
          </div>

          {!!reCaptchaSiteKey && (
            <ReCAPTCHA ref={recaptchaRef} badge="bottomleft" sitekey={reCaptchaSiteKey} size="invisible" />
          )}
        </div>
      )}
    </div>
  );
};

const CommentFeed = props => {
  const {
    post: { comments, solved, id },
  } = props;
  const { isLoggedIn, isAdminUser } = useUserPermissions();
  const { user, permissions, _id: userId } = useContext(UserContext);
  let baseUrl = useContext(BaseUrlContext);
  baseUrl = baseUrl === '/' ? '' : baseUrl;
  const { isClient } = useEnvInfo;

  // Track comments via useState in an object keyed on the comment ID, for easy updating when adding or deleting a comment.
  const currentComments = {};
  const [postComments, setPostComments] = useState(currentComments);
  useEffect(() => {
    comments.forEach(comment => {
      currentComments[comment.id] = comment;
    });
    setPostComments(currentComments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (errorMessage) {
      notify(<ErrorNotification>{errorMessage.message}</ErrorNotification>);
    }
  });

  return (
    <>
      {Object.values(postComments)?.map(comment => (
        <Comment
          key={`comment-id-${comment.id}`}
          {...{
            baseUrl,
            comment,
            isAdminUser,
            isLoggedIn,
            isClient,
            permissions,
            postComments,
            setErrorMessage,
            setPostComments,
            userId,
            user,
          }}
        />
      ))}
      <AddComment
        {...{
          baseUrl,
          id,
          isClient,
          isLoggedIn,
          isAdminUser,
          postComments,
          setErrorMessage,
          setPostComments,
          solved,
          user,
        }}
      />
    </>
  );
};

AddComment.propTypes = {
  baseUrl: PropTypes.string,
  id: PropTypes.string,
  isAdminUser: PropTypes.bool,
  isClient: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  postComments: PropTypes.any,
  setErrorMessage: PropTypes.func,
  setPostComments: PropTypes.func,
  solved: PropTypes.bool,
  user: PropTypes.object,
};

Comment.propTypes = {
  baseUrl: PropTypes.string,
  comment: PropTypes.object,
  isAdminUser: PropTypes.bool,
  isClient: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  permissions: PropTypes.array,
  postComments: PropTypes.any,
  setErrorMessage: PropTypes.func,
  setPostComments: PropTypes.func,
  user: PropTypes.object,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CommentFeed.propTypes = {
  post: PropTypes.shape({
    comments: PropTypes.array,
    id: PropTypes.string,
    solved: PropTypes.bool,
  }),
};

ConfirmDeleteModal.propTypes = {
  comment: PropTypes.object,
  commentUrl: PropTypes.string,
  modalRef: PropTypes.any,
  permanent: PropTypes.bool,
  postComments: PropTypes.any,
  setPostComments: PropTypes.func,
  target: PropTypes.string,
};

Content.propTypes = {
  comment: PropTypes.object,
  commentUrl: PropTypes.string,
  isAdminUser: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  postComments: PropTypes.any,
  setIsEditing: PropTypes.func,
  setPostComments: PropTypes.func,
  user: PropTypes.object,
};

ContentEditor.propTypes = {
  comment: PropTypes.object,
  commentUrl: PropTypes.string,
  postComments: PropTypes.any,
  setIsEditing: PropTypes.func,
  setPostComments: PropTypes.func,
};

Meta.propTypes = {
  createdAt: PropTypes.string,
  edited: PropTypes.bool,
  user: PropTypes.object,
};

ModifyCommentButton.propTypes = {
  icon: PropTypes.string,
  onClickAction: PropTypes.func,
};

SolvedBlock.propTypes = {
  body: PropTypes.string,
  solved: PropTypes.bool,
  userName: PropTypes.string,
};

Vote.propTypes = {
  baseUrl: PropTypes.string,
  comment: PropTypes.object,
  isClient: PropTypes.bool,
  permissions: PropTypes.array,
  setErrorMessage: PropTypes.func,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default CommentFeed;
