import PropTypes from 'prop-types';
import React from 'react';

import DiscussPost from '../Post';
import classes from '../style.module.scss';

import CommentFeed from './CommentFeed';

const DiscussPage = ({ discuss: post }) => (
  <div className={classes.DiscussPage}>
    {!!post && (
      <>
        <DiscussPost {...{ post }} />
        <CommentFeed {...{ post }} />
      </>
    )}
  </div>
);

DiscussPage.propTypes = {
  discuss: PropTypes.object,
};

export default DiscussPage;
